import React, { Component, useContext } from "react";
import Select from "react-select";
import { Helmet } from "react-helmet";
import Toggle from "react-toggle";
import DayPickerInput from "react-day-picker/DayPickerInput";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "react-day-picker/lib/style.css";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import icon1 from "./icons/editImg.png";
import icon2 from "./icons/addyellow.png";
import icon3 from "./icons/subtractyellow.png";
import icon4 from "./icons/delete.png";
import placeholder from "./images/barPlaceholderAlt.png";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import AccessTokenContext from "./AccessTokenContext";
const options = [
  { value: "Beer (pint)", label: "Beer (pint)" },
  { value: "Beer bottle", label: "Beer bottle" },
  { value: "Wine", label: "Wine" },
  { value: "Cocktail", label: "Cocktail" },
  { value: "Long drink", label: "Long drink" },
  { value: "Shot", label: "Shot" },
  { value: "Soft drink", label: "Soft drink" },
];

const optionsCategory = [
  { value: "After work", label: "After work" },
  { value: "Happy hour", label: "Happy hour" },
  { value: "Concert", label: "Concert" },
  { value: "Quiz", label: "Quiz" },
  { value: "Festival", label: "Festival" },
  { value: "Live music", label: "Live music" },
  { value: "DJ", label: "DJ" },
  { value: "Live sport", label: "Live sport" },
  { value: "Beer tasting", label: "Beer tasting" },
  { value: "Spirits tasting", label: "Spirits tasting" },
  { value: "Wine tasting", label: "Wine tasting" },
  { value: "Drink tasting", label: "Drink tasting" },
  { value: "Student event", label: "Student event" },
  { value: "Contest", label: "Contest" },
  { value: "Speed dating", label: "Speed dating" },
  { value: "Brunch", label: "Brunch" },
  { value: "After-ski", label: "After-ski" },
  { value: "Dish of the day", label: "Dish of the day" },
];

const highlighted = {
  from: new Date(2018, 0, 12),
  to: new Date(2018, 0, 16),
};

class Event extends Component {

  static contextType = AccessTokenContext;

  constructor(props) {
    super(props);
    this.state = {
      barInfo: {},
      image: null,
      url: placeholder,
      date: "",
      multiDate: "",
      standingDay: "",
      standingDayMulti: "",
      hours: "00:00-00:00",
      id: "",
      type: "",
      info: "",
      title: "",
      drinkList: [],
      drinks: [],
      multiday: false,
      reoccuring: false,
      weekday: "",
      weekdayStyle: "displayNone",
      charCount: 0,
      warningVar: false,
      drinkMessage: "drinkEmpty",
      cropToggle: false,

      src: null,
      crop: {
        unit: "%",
        width: 70,
        height: 70,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
      dayOptions: [
        { value: "1", label: this.props.t("overview.monday") },
        { value: "2", label: this.props.t("overview.tuesday") },
        { value: "3", label: this.props.t("overview.wednesday") },
        { value: "4", label: this.props.t("overview.thursday") },
        { value: "5", label: this.props.t("overview.friday") },
        { value: "6", label: this.props.t("overview.saturday") },
        { value: "7", label: this.props.t("overview.sunday") },
      ],
    };
  }

  search(valueKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].value === valueKey) {
        return myArray[i];
      }
    }
  }

  async componentDidMount() {
    const eventData = localStorage.getItem("event");
    const eventInfo = JSON.parse(eventData);
    const bardata = localStorage.getItem("info");
    const barInfo = JSON.parse(bardata);
    let reoccuring = false;
    let multiday = false;
    let standingDay = "";
    let standingDayMulti = "";
    let date = eventInfo.date;
    if (eventInfo.date.length == 3) {
      standingDay = this.search(eventInfo.date[0], this.state.dayOptions);
      standingDayMulti = this.search(eventInfo.date[2], this.state.dayOptions);
      reoccuring = true;
      this.handleReoccuring();
      if (standingDay != standingDayMulti) {
        multiday = true;
        this.handleMultiday();
      }
    }
    let multiDate = "";
    if (eventInfo.date.includes(" ")) {
      const dateSplit = eventInfo.date.split(" ");
      date = dateSplit[0];
      multiDate = dateSplit[2];
      multiday = true;
      this.handleMultiday();
    }
    await this.setState({
      barInfo,
      reoccuring,
      multiday,
      standingDay,
      standingDayMulti,
      type: eventInfo.type,
      date,
      multiDate,
      hours: eventInfo.hours,
      id: eventInfo.id,
      info: eventInfo.info,
      charCount: eventInfo.info.length,
      drinks: eventInfo.drinks,
      title: eventInfo.title,
    });

    let drinkList = [];
    let drinks = [];
    for (let index = 0; index < eventInfo.drinks.length; index++) {
      let drinkSplit = eventInfo.drinks[index].split(" ");
      let price = drinkSplit.pop();
      drinkList = [
        ...drinkList,
        <div className="drinkDiv">
          <Select
            className="categorySelect drinkSelect"
            placeholder={drinkSplit.join(" ")}
            options={options}
            onChange={(drink) => {
              this.handleChange();
              this.editDrinkName(index, drink.label);
            }}
          />
          <div>
            <input
              className="drinkPrice"
              type="text"
              placeholder={price}
              onChange={(event) => {
                this.handleChange();
                this.editDrinkCost(index, event.target.value);
              }}
            ></input>
          </div>
        </div>,
      ];

      drinks = [...drinks, { cat: drinkSplit.join(" "), price: price }];
    }
    this.setState({ drinkList, drinks });
    firebase
      .storage()
      .ref("eventImages")
      .child(barInfo.id + eventInfo.id)
      .getDownloadURL()
      .then((url) => {
        this.setState({ url });
      });
    if (eventInfo.drinks.length > 0) {
      this.setState({
        drinkMessage: "displayNone",
      });
    }
    if (this.state.multiday && this.state.reoccuring) {
      document.getElementById("weekdayPickerMulti").style.display = "initial";
      document.getElementById("dayPickerMulti").style.display = "none";
      document.getElementById("eventDayDiv").style.height = "110px";
    }
  }

  handleChange() {
    document.getElementById("saveButton").style.visibility = "visible";
    document.getElementById("saveButton").style.opacity = "1";
  }

  async handleNotification(barIds, barInfo) {
    try {
      const usersCollection = await firebase
        .firestore()
        .collection("users")
        .get();
      const allUserData = [];

      usersCollection.forEach((doc) => {
        const userData = doc.data();
        allUserData.push(userData);
      });

      const filteredArray = allUserData.filter(
        (user) => user.favorites && user.favorites.length > 0
      );
      console.log('====================================');
      console.log("barIds===>>" + JSON.stringify(barIds));
      console.log('====================================');
      // const userDataWithTargetId = filteredArray.find((user) =>
      //   user.favorites.includes(barIds)
      // );

      const userDataWithTargetId = filteredArray.filter((user) =>
        user.favorites.some((favoriteId) => barIds.includes(favoriteId))
      );
      console.log('====================================');
      console.log("userDataWithTargetId===>>" + JSON.stringify(userDataWithTargetId));
      console.log('====================================');

      if (userDataWithTargetId !== undefined) {
        this.getUserDataArray(userDataWithTargetId, allUserData, barIds, barInfo);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async getUserDataArray(matchedUser, allUserDataArray, barIds, barInfo) {
    console.log('====================================');
    console.log("matchedUser===>>" + JSON.stringify(matchedUser));
    console.log('====================================');

    const accessTokensss = localStorage.getItem("@accessToken");


    const filterdata = allUserDataArray.filter(
      (user) => matchedUser.some(x => x.uid === user.uid)
    );

    console.log('====================================');
    console.log("hghghgh===>>" + JSON.stringify(filterdata));
    console.log('====================================');

    const deviceIds = filterdata.map((x) => x.deviceToken);
    console.log('====================================');
    console.log("barInfo===>>" + JSON.stringify(barInfo));
    console.log('====================================');

    const fcmEndpoint = "https://fcm.googleapis.com/v1/projects/barzone-e3783/messages:send";
    const serverKey = accessTokensss;

    // Use for...of to handle async calls properly
    for (const deviceToken of deviceIds) {
      const payload = {
        message: {
          token: deviceToken,
          notification: {
            title: this.state.title,
            body: "Event is updated!",
          },
          data: {
            type: "Update",
            barIds: barIds,
            barInfo: JSON.stringify(barInfo),
          },
        },
      };

      try {
        const response = await fetch(fcmEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + serverKey,
          },
          body: JSON.stringify(payload),
        });

        const result = await response.json();
        console.log('====================================');
        console.log("API response===>>", result);
        console.log('====================================');
      } catch (error) {
        console.error("Error sending FCM:", error);
      }
    }
  }


  handleSaveData = () => {
    document.getElementById("saveButton").style.opacity = "0";
    setTimeout(function () {
      if (document.getElementById("saveButton")) {
        document.getElementById("saveButton").style.visibility = "hidden";
      }
    }, 1000);
    if (this.state.image != null) {
      const uploadTask = firebase
        .storage()
        .ref(`eventImages/${this.state.barInfo.id + this.state.id}`)
        .put(this.state.image);
      uploadTask.on(
        "state_changed",
        () => {
          //progress
        },
        () => {
          alert("The image could not be uploaded, try again!");
        },
        () => {
          this.props.history.push("/events");
        }
      );
    }
    this.setState({
      cropToggle: false,
      src: this.state.image,
    });

    const ref = firebase
      .firestore()
      .collection("bars")
      .doc(this.state.barInfo.id);
    ref
      .get()
      .then((doc) => {
        if (doc.exists) {
          let events = doc.data().events;
          let drinks = [];
          this.state.drinks.forEach((drink) => {
            if (drink.cat !== "") {
              drinks = [...drinks, drink.cat + " " + drink.price];
            }
          });
          let date = "";
          if (!this.state.reoccuring) {
            date = this.state.date;
            let dateMulti = this.state.multiDate;
            if (date.includes("/")) {
              date = date.split("/");
              date = date[2] + "-" + date[1] + "-" + date[0];
            }
            if (dateMulti.includes("/")) {
              dateMulti = dateMulti.split("/");
              dateMulti =
                dateMulti[2] + "-" + dateMulti[1] + "-" + dateMulti[0];
            }
            if (this.state.multiday) {
              date = date + " - " + dateMulti;
            }
          } else {
            if (!this.state.multiday) {
              date =
                this.state.standingDay.value +
                "-" +
                this.state.standingDay.value;
            } else {
              date =
                this.state.standingDay.value +
                "-" +
                this.state.standingDayMulti.value;
            }
          }
          let eventExists = false;
          events.forEach((event) => {
            if (event.id === this.state.id) {
              event.hours = this.state.hours;
              event.date = date;
              event.info = this.state.info;
              event.type = this.state.type.value || this.state.type;
              event.drinks = drinks;
              event.title = this.state.title;
              eventExists = true;
            }
          });
          if (!eventExists) {
            events = [
              ...events,
              {
                hours: this.state.hours,
                date: date,
                info: this.state.info,
                type: this.state.type.value || this.state.type,
                title: this.state.title,
                drinks: drinks,
                id: this.state.id,
              },
            ];
          }

          this.handleNotification(this.state.barInfo.id, this.state.barInfo);
          ref.update({
            events: events,
          });
          let barInfo = this.state.barInfo;
          barInfo.events = events;
          localStorage.setItem("info", JSON.stringify(barInfo));
          let eventInfo = {
            type: this.state.type.value || this.state.type,
            date: this.state.date,
            hours: this.state.hours,
            id: this.state.id,
            info: this.state.info,
            drinks: drinks,
            title: this.state.title,
          };
          localStorage.setItem("event", JSON.stringify(eventInfo));
          if (this.state.image == null) {
            this.props.history.push("/events");
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

    const updateRef = firebase
      .firestore()
      .collection("users")
      .where("favorites", "array-contains", this.state.barInfo.id);
    updateRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let updates = doc.data().updates || [];
        updates = [...updates, this.state.barInfo.id];
        doc.ref.update({
          updates: updates,
        });
      });
    });
  };

  handleUpload = (e) => {
    let image = "";
    if (e.target.files[0]) {
      image = e.target.files[0];
      if (image.size < 15000000) {
        const url = URL.createObjectURL(image);
        this.setState({ image, url });
      } else {
        alert("Image is too large, try with another one.");
      }
    }
    if (image.size < 15000000) {
      this.handleChange();
    }
  };

  addDrink = () => {
    const index = this.state.drinkList.length;
    const drinkList = [
      ...this.state.drinkList,
      <div className="drinkDiv">
        <Select
          className="categorySelect drinkSelect"
          options={options}
          onChange={(drink) => {
            this.handleChange();
            this.editDrinkName(index, drink.label);
          }}
        />
        <div>
          <input
            className="drinkPrice"
            type="text"
            placeholder="0"
            onChange={(event) => {
              this.handleChange();
              this.editDrinkCost(index, event.target.value);
            }}
          ></input>
        </div>
      </div>,
    ];
    const drinks = [...this.state.drinks, { cat: "", price: 0 }];
    this.setState({ drinkList, drinks });
    if (this.state.drinks != []) {
      this.setState({
        drinkMessage: "displayNone",
      });
    }
  };

  removeDrink = () => {
    let drinkList = [...this.state.drinkList];
    let drinks = [...this.state.drinks];
    drinkList.pop();
    drinks.pop();
    this.setState({ drinkList, drinks });
    if (this.state.drinks.length == 1) {
      this.setState({
        drinkMessage: "drinkEmpty",
      });
    }
  };

  editDrinkName = (index, drink) => {
    let drinks = this.state.drinks;
    drinks[index].cat = drink;
    this.setState({ drinks });
  };

  editDrinkCost = (index, price) => {
    let drinks = this.state.drinks;
    drinks[index].price = price;
    this.setState({ drinks });
  };

  handleMultiday = () => {
    if (!this.state.multiday && !this.state.reoccuring) {
      document.getElementById("eventDayDiv").style.height = "80px";
      document.getElementById("dayPickerMulti").style.display = "initial";
      document.getElementById("weekdayPickerMulti").style.display = "none";
    } else if (!this.state.multiday && this.state.reoccuring) {
      document.getElementById("eventDayDiv").style.height = "110px";
      document.getElementById("dayPickerMulti").style.display = "none";
      document.getElementById("weekdayPickerMulti").style.display = "initial";
    } else if (this.state.reoccuring) {
      document.getElementById("eventDayDiv").style.height = "60px";
      document.getElementById("dayPickerMulti").style.display = "none";
      document.getElementById("weekdayPickerMulti").style.display = "none";
    } else {
      document.getElementById("eventDayDiv").style.height = "40px";
      document.getElementById("dayPickerMulti").style.display = "none";
      document.getElementById("weekdayPickerMulti").style.display = "none";
    }
    this.setState({ multiday: !this.state.multiday });
  };

  handleReoccuring = () => {
    let ele = document.getElementsByClassName("DayPickerInput");
    if (this.state.multiday && this.state.reoccuring) {
      this.setState({
        weekdayStyle: "displayNone",
      });
      document.getElementById("eventDayDiv").style.height = "80px";
      for (var i = 0; i < ele.length; i++) {
        ele[i].style.display = "inline-block";
      }
      document.getElementById("weekdayPickerMulti").style.display = "none";
      document.getElementById("dayPickerMulti").style.display = "initial";
    } else if (this.state.multiday && !this.state.reoccuring) {
      this.setState({
        weekdayStyle: "weekdaySelect",
      });
      document.getElementById("eventDayDiv").style.height = "110px";
      for (var i = 0; i < ele.length; i++) {
        ele[i].style.display = "none";
      }
      document.getElementById("weekdayPickerMulti").style.display = "initial";
      document.getElementById("dayPickerMulti").style.display = "none";
    } else if (!this.state.reoccuring) {
      this.setState({
        weekdayStyle: "weekdaySelect",
      });
      document.getElementById("eventDayDiv").style.height = "60px";
      for (var i = 0; i < ele.length; i++) {
        ele[i].style.display = "none";
      }
    } else {
      this.setState({
        weekdayStyle: "displayNone",
      });
      document.getElementById("eventDayDiv").style.height = "40px";
      for (var i = 0; i < ele.length; i++) {
        ele[i].style.display = "inline-block";
      }
    }
    this.setState({ reoccuring: !this.state.reoccuring });
  };

  handleWarning = () => {
    if (!this.state.warningVar) {
      document.getElementById("outerWarning").style.display = "initial";
      this.setState({
        warningVar: true,
      });
    } else {
      document.getElementById("outerWarning").style.display = "none";
    }
    this.setState({
      warningVar: false,
    });
    let barInfo = this.state.barInfo;
    const ref = firebase.firestore().collection("bars").doc(barInfo.id);
    ref
      .get()
      .then((doc) => {
        if (doc.exists) {
          let events = [];
          doc.data().events.forEach((event) => {
            if (event.id != this.state.id) {
              events = [...events, event];
            }
          });
          ref.update({
            events,
          });
          barInfo.events = events;
          localStorage.setItem("info", JSON.stringify(barInfo));
          this.props.history.push("/events");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  handleRevert = () => {
    document.getElementById("outerWarning").style.display = "none";
    this.setState({
      warningVar: false,
    });
  };

  deleteEvent() {
    if (!this.state.warningVar) {
      document.getElementById("outerWarning").style.display = "initial";
      this.setState({
        warningVar: true,
      });
    } else {
      document.getElementById("outerWarning").style.display = "none";
    }
  }

  //handle crop

  handleFile = (e) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        src: fileReader.result,
        cropToggle: true,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  handleSubmit = (e) => {
    this.handleChange();
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const url = this.getCroppedImg(this.imageRef, crop);
      this.setState({ url });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({
      image: croppedImage,
      url: URL.createObjectURL(croppedImage),
    });
  }

  render() {
    const { t } = this.props;
    const { crop, profile_pic, src } = this.state;
    return (
      <div>
        <Helmet>
          <style>
            {
              "body { background-color: #F7F7F7 !important; } @media screen and (max-width: 1025px) { .css-yk16xz-control, .css-1hwfws3{height:30px !important;}}"
            }
          </style>
        </Helmet>
        <div className="outerWarning" id="outerWarning">
          <div className="innerWarning">
            <p className="warningText">{t("event.sureDelete")}</p>
            <button
              className="warningButton goBackBtn"
              onClick={() => this.handleRevert()}
            >
              {t("event.cancel")}
            </button>
            <button
              className="warningButton"
              onClick={() => {
                this.handleWarning();
              }}
            >
              {t("event.delete")}
            </button>
          </div>
        </div>
        <div className="saveDiv">
          <a
            id="saveButton"
            className="yellowBtn linkStyle saveButton boxShadow"
            onClick={this.handleSaveData}
          >
            {t("event.save")}
          </a>
        </div>
        <div className="eventsTitleBar">
          <p className="tabTitle floatLeft">{t("event.event")}</p>
          <a className="deleteButton" onClick={() => this.deleteEvent()}>
            <img className="deleteIcon boxShadow" src={icon4}></img>
          </a>
        </div>
        <div className="content contentWide boxShadow">
          <div className="innerEventLeft">
            <div>
              <p className="contentTitle topTitle">{t("event.title")}</p>
              <input
                className="longInput eventInput"
                type="text"
                value={this.state.title}
                onChange={(event) => {
                  this.handleChange();
                  this.setState({ title: event.target.value });
                }}
              ></input>
              <p className="contentTitle">{t("event.category")}</p>

              <Select
                className="categorySelectEvent"
                placeholder={this.state.type}
                onChange={(type) => {
                  this.setState({ type });
                  this.handleChange();
                }}
                options={optionsCategory}
              />
              {/* {this.state.barInfo.subscription != 2 &&
                !this.state.barInfo.trial && (
                  <input
                    className="longInput eventInput"
                    type="text"
                    value={"Happy hour"}
                  ></input>
                )}
              {(this.state.barInfo.subscription == 2 ||
                this.state.barInfo.trial) && (
                <Select
                  className="categorySelectEvent"
                  placeholder={this.state.type}
                  onChange={(type) => {
                    this.setState({ type });
                    this.handleChange();
                  }}
                  options={optionsCategory}
                />
              )} */}
              <p className="contentTitle">
                {t("event.description")}{" "}
                <span className="charCount">{this.state.charCount}/200</span>
              </p>
              <textarea
                className="blockInput eventInput"
                value={this.state.info}
                type="text"
                placeholder=""
                onChange={(event) => {
                  if (event.target.value.length <= 200) {
                    this.handleChange();
                    this.setState({
                      info: event.target.value,
                      charCount: event.target.value.length,
                    });
                  }
                }}
              ></textarea>
            </div>
            <div>
              <p className="contentTitle">
                {t("event.prices")}
                <span className="iconAddDiv">
                  <a className="linkStyle">
                    <img
                      className="iconAdd"
                      onClick={() => this.addDrink()}
                      src={icon2}
                      alt="icon"
                    ></img>
                  </a>
                  <a className="linkStyle">
                    <img
                      className="iconAdd"
                      onClick={() => {
                        this.removeDrink();
                        this.handleChange();
                      }}
                      src={icon3}
                      alt="icon"
                    ></img>
                  </a>
                </span>
              </p>
              <p className={this.state.drinkMessage}>
                {t("event.pressButton")}
              </p>
              {this.state.drinkList}
            </div>
          </div>
          <div className="innerEventRight">
            <div>
              <p className="contentTitle topTitle">{t("event.displayImage")}</p>
              <div>
                <Form onChange={this.handleSubmit}>
                  <label htmlFor="profile_pic"></label>
                  <input
                    className="imgFileInput"
                    name="file"
                    id="file"
                    type="file"
                    onChange={this.handleFile}
                  ></input>
                  {this.state.cropToggle && (
                    <div>
                      <ReactCrop
                        className="cropBox"
                        src={src}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    </div>
                  )}
                </Form>
                <label for="file">
                  {!this.state.cropToggle && (
                    <div>
                      <img
                        className="imageBox imgEvent"
                        src={this.state.url}
                        height={180}
                        width={180}
                      ></img>
                      <img className="editIcon boxShadow" src={icon1}></img>
                    </div>
                  )}
                </label>
              </div>
            </div>
            <div>
              <p className="contentTitle">{t("event.timeDate")}</p>
              <div className="eventDayDiv" id="eventDayDiv">
                <Select
                  className={this.state.weekdayStyle}
                  placeholder={this.state.standingDay.label}
                  onChange={(standingDay) => {
                    this.setState({ standingDay });
                    this.handleChange();
                  }}
                  options={this.state.dayOptions}
                />
                <div id="weekdayPickerMulti">
                  <p className="sameLineWeekday">to</p>
                  <Select
                    className={this.state.weekdayStyle}
                    placeholder={this.state.standingDayMulti.label}
                    onChange={(standingDayMulti) => {
                      this.setState({ standingDayMulti });
                      this.handleChange();
                    }}
                    options={this.state.dayOptions}
                  />
                </div>
                <DayPickerInput
                  modifiers={highlighted}
                  placeholder={this.state.date}
                  onDayChange={(value) => {
                    this.handleChange();
                    if (value) {
                      let dateString = value.toLocaleDateString();
                      if (dateString.includes("/")) {
                        let split = dateString.split("/");
                        dateString = split[2] + "-" + split[1] + "-" + split[0];
                      }
                      this.setState({ date: dateString });
                    }
                  }}
                />
                <div id="dayPickerMulti">
                  <p className="sameLine">to</p>
                  <DayPickerInput
                    modifiers={highlighted}
                    placeholder={this.state.multiDate}
                    onDayChange={(value) => {
                      this.handleChange();
                      value &&
                        this.setState({
                          multiDate: value.toLocaleDateString(),
                        });
                    }}
                  />
                </div>
              </div>
              <div className="eventTimeDiv">
                <TimePicker
                  placeholder={this.state.hours.substring(0, 5)}
                  showSecond={false}
                  minuteStep={30}
                  onChange={(value) => {
                    try {
                      this.handleChange();
                      this.setState({
                        hours:
                          value.format("HH:mm:ss").substring(0, 5) +
                          "-" +
                          this.state.hours.substring(6, 11),
                      });
                    } catch { }
                  }}
                />{" "}
                -{" "}
                <TimePicker
                  placeholder={this.state.hours.substring(6, 11)}
                  showSecond={false}
                  minuteStep={30}
                  onChange={(value) => {
                    try {
                      this.handleChange();
                      this.setState({
                        hours:
                          this.state.hours.substring(0, 5) +
                          "-" +
                          value.format("HH:mm:ss").substring(0, 5),
                      });
                    } catch { }
                  }}
                />
              </div>
              <div className="toggleDiv">
                <label>
                  <span className="eventSub">{t("event.reoccuring")}</span>
                  <Toggle
                    className="toggleBtn"
                    checked={this.state.reoccuring}
                    icons={false}
                    onChange={() => {
                      this.handleChange();
                      this.handleReoccuring();
                    }}
                  />
                </label>
                <div className="toggleDistance"></div>
                <label>
                  <span className="eventSub">{t("event.multiday")}</span>
                  <Toggle
                    className="toggleBtn"
                    icons={false}
                    checked={this.state.multiday}
                    onChange={() => {
                      this.handleChange();
                      this.handleMultiday();
                    }}
                  />
                </label>
                <hr className="lineBreak"></hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Event);
